<template >
  <div class="body">
	  <navTop></navTop>
    <div class="wrap">
      <div class="wrap-left">
        <menuLeft :active="menuIndex"></menuLeft>
      </div>
      <div class="wrap-right">
        <div class="wrap-right-header">
          <div class="wrh-left">
           
            <div class="wrh-left-number">{{ userInfo.buyTotal }}</div>
            <div class="wrh-left-score">{{ $t("capital.buyTotal") }}</div>
            
          </div>
          <div class="wrh-right">
            <div class="wrh-right-number">{{ userInfo.refundTotal }}</div>
            <div class="wrh-right-coupon">{{ $t("capital.refundTotal") }}</div>
            
          </div>
        </div>
		<div style="height:30px;background-color: #eee;"></div>
        <div class="wrap-right-body">
			<div class="wrh-header">
				<div>{{ $t("applyInvoice.gongpinMall") }}</div>
				<div>{{ $t("applyInvoice.orderTime") }}:{{orderTime}}</div>
			</div>
          <table class="tab-header" border="0">
            <tr>
              <td colspan="2" width="50%" style="text-align: left;padding-left: 20px;">
                {{ $t('userCenter.goodsInfo') }}
              </td>
              <td width="20%">{{ $t('userCenter.val') }}</td>
              <td width="20%">{{ $t('userCenter.price') }}</td>
              <td style="text-align: right;padding-right: 30px;">{{ $t('userCenter.number') }}</td>
            </tr>
          </table>
          <table class="tab-body" border="0" v-for="(item,index) in order" cellpadding="0" cellspacing="0">
            <tr v-for="goods in item.goodsInfo">
              <td width="15%">{{ goods.img }}</td>
              <td width="35%" style="text-align: left;">
                <div style="line-height:24px;">{{ $lang(goods, "goodsInfo") }}</div>
                <div style="color:#888;margin-top:5px;">{{ $lang(goods, "spec") }}</div>
              </td>
              <td width="20%" style="font-size: 24px;font-weight: 800;color:brown;">{{ goods.val }}</td>
              <td width="20%">￥{{ goods.price }}</td>
              <td>{{ goods.number }}</td>
            </tr>
            <tr>
              <td colspan="2" style="text-align: left;padding:10px 20px;background-color: #eee;"><span
                  style="color: #888;margin-right:5px;">{{ $lang(item.subtotalText, 'subtotal') }}</span><span
                  style="color:red;font-size: 16px;">￥{{ item.subtotal }}</span></td>
              <td colspan="3" style="text-align: right;padding:10px 20px;background-color: #eee;color:#888;">
                {{ $lang(item.payTimeText, 'payTime') }}{{ item.payTime }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import menuLeft from "@/components/MenuLeft";
import navTop from "@/components/navTop";
export default {
	components: {menuLeft,navTop},
	data() {
		return {
			menuIndex:'3',
			orderTime:"2020-06-09 09:24:34",
			userInfo:{
				buyTotal: 897,
				refundTotal: 810,
			},			
			order: [
				{
				  subtotalText: {
					subtotalZhCn: "小计",
					subtotalEnUs: "Subtotal"
				  },
				  subtotal: '12800.00',
				  payTimeText: {
					payTimeZhCn: "交易时间：",
					payTimeEnUs: "Pay time："
				  },
				  payTime: '2020-06-09 09:24:34',
				  orderTime:'2020-06-09 09:24:34',
				  goodsInfo: [{
					img: '',
					goodsInfoZhCn: '小型列管换热器 无菌换热器卫生及换热器微型换热器微通道换热器',
					goodsInfoEnUs: 'Small tube heat exchanger aseptic heat exchanger sanitary heat exchanger miniature heat exchanger microchannel heat exchanger',
					specZhCn: '商品规格：FY-15ZDPIC',
					specEnUs: 'Goods Specs：FY-15ZDPIC',
					val: '+56',
					price: '12800.00',
					number: '1'
				  }]
				},
			]
		}
	},
	methods: {
		
	}
}
</script>
<style  scoped>
.body {
}

.wrap {
  margin: 0 auto;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.wrap-left {
  font-size: 14px;
  width: 20%;
  min-height: 800px;
  background-color: #fff;
  padding: 20px 15px 40px;
}

.wrap-right {
  width: 78%;
  min-height: 800px;
  background-color: #fff;
}

.wrap-right-header {
  display: flex;
  justify-content: space-between;
  background-color: #555;
  color: #fff;
  padding:40px;
}

.wrh-left {

  line-height: 40px;
  width: 50%;
  text-align: center;
}

.wrh-left-lv {
  font-size: 24px;
  color: yellow;
}

.wrh-left-number {
  font-size: 40px;
  color: #5DAF34;
  font-weight: 800;
}

.wrh-left-score {
  color: #ccc;

}
.wrh-header{
	display: flex;
	justify-content: space-between;
	background-color: #555;
	color:#fff;
	padding:10px 30px;
	font-size: 14px;
	margin-bottom: 20px;
}
.wrh-right {
  
  line-height: 40px;
  width: 50%;
  text-align: center;
  border-left: 1px solid #fff;
  
}

.wrh-right-number {
  
  font-size: 40px;
  color: darkcyan;
  font-weight: 800;
}

.wrh-right-coupon {
  color: #ccc;
  margin-bottom: 5px;
}

.tab-header {
  width: 100%;
}

.tab-header tr td {
  font-size: 14px;
  text-align: center;
  
  color: #888;
}

.tab-body {
  width: 100%;
}

.tab-body tr td {
  font-size: 14px;
  padding: 40px 0px;
  text-align: center;
}
</style>
